var Arena = /** @class */ (function () {
    function Arena(w, h) {
        var matrix = [];
        while (h--) {
            // @ts-ignore
            matrix.push(new Array(w).fill(0));
        }
        this.matrix = matrix;
    }
    Arena.prototype.clear = function () {
        this.matrix.forEach(function (row) { return row.fill(0); });
    };
    /**
     * Calculate landing position (without colliding)
     * @param player
     * @returns {number}: y-value of player position
     */
    Arena.prototype.landingPosition = function (player) {
        var collide = false;
        var playerPos = JSON.parse(JSON.stringify(player.pos)); // deep object copy
        while (!collide) {
            playerPos.y++;
            collide = this.collide(player, playerPos);
        }
        return --playerPos.y;
    };
    /**
     * check if player collides with arena
     * @param player
     * @param pos: optional player position
     * @returns {boolean}
     */
    Arena.prototype.collide = function (player, pos) {
        if (pos === void 0) { pos = null; }
        var _a = [player.matrix, pos ? pos : player.pos], m = _a[0], o = _a[1];
        for (var y = 0; y < m.length; ++y) {
            for (var x = 0; x < m[y].length; ++x) {
                if (m[y][x] !== 0 &&
                    (this.matrix[y + o.y] && this.matrix[y + o.y][x + o.x]) !== 0) { //y = Höhe innerhalb der Item Matrix / o.y Item Position (oberste Kante des Items zur Spieloberkante)
                    return true;
                }
            }
        }
        return false;
    };
    Arena.prototype.merge = function (player) {
        var _this = this;
        player.matrix.forEach(function (row, y) {
            row.forEach(function (value, x) {
                if (value !== 0) {
                    _this.matrix[y + player.pos.y][x + player.pos.x] = value;
                }
            });
        });
    };
    Arena.prototype.sweep = function (player) {
        var rowCount = 1;
        outer: for (var y = this.matrix.length - 1; y > 0; --y) {
            for (var x = 0; x < this.matrix[y].length; ++x) {
                if (this.matrix[y][x] === 0) {
                    continue outer;
                }
            }
            var row = this.matrix.splice(y, 1)[0].fill(0);
            this.matrix.unshift(row);
            ++y;
            player.score += rowCount * 100;
            rowCount *= 2;
        }
    };
    return Arena;
}());
export default Arena;
