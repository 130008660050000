import Preview from "./preview";
var Player = /** @class */ (function () {
    function Player(tetris) {
        this.tetris = tetris;
        this.arena = tetris.arena;
        var canvasPreview = document.getElementById('preview');
        this.preview = new Preview(canvasPreview);
        this.dropCounter = 0;
        this.dropInterval = 1000;
        this.pos = { x: 0, y: 0 };
        this.matrix = null;
        this.score = 0;
        this.reset(true);
    }
    Player.prototype.drop = function () {
        if (!this.tetris.pause) {
            this.pos.y++;
            if (this.arena.collide(this)) {
                this.tetris.playSound('jump.mp3');
                this.pos.y--;
                this.arena.merge(this);
                this.reset();
                this.arena.sweep(this);
                this.tetris.updateScore(this.score);
            }
            this.dropCounter = 0; // reset counter to have full interval (=1000ms) of time
        }
    };
    Player.prototype.move = function (direction) {
        if (!this.tetris.pause) {
            this.pos.x += direction;
            if (this.arena.collide(this)) {
                this.pos.x -= direction;
            }
        }
    };
    Player.prototype.moveToBottom = function () {
        if (!this.tetris.pause) {
            this.pos.y = this.arena.landingPosition(this);
            this.dropCounter = 1000;
        }
    };
    Player.prototype.reset = function (init) {
        if (init === void 0) { init = false; }
        var pieces = 'ILJOTSZ';
        if (init) {
            this.matrix = this.tetris.createPiece(pieces[pieces.length * Math.random() | 0]);
        }
        else {
            this.matrix = this.nextMatrix;
        }
        // save next matrix
        this.nextMatrix = this.tetris.createPiece(pieces[pieces.length * Math.random() | 0]);
        // generate preview
        this.preview.drawMatrix(this.nextMatrix);
        this.pos.y = 0;
        this.pos.x =
            (this.arena.matrix[0].length / 2 | 0) -
                // @ts-ignore
                (this.matrix[0].length / 2 | 0);
        // lose game if player collides with top of arena
        if (this.arena.collide(this)) {
            this.arena.clear();
            this.score = 0;
            this.tetris.updateScore(this.score);
            this.tetris.pause = true;
            this.tetris.gameOver = true;
            this.tetris.showOverlay(true, 'Game Over', false, true);
        }
    };
    Player.prototype.rotate = function (direction) {
        if (!this.tetris.pause) {
            var pos = this.pos.x;
            var offset = 1;
            this.rotateMatrix(this.matrix, direction);
            // prevent rotation into the wall
            while (this.arena.collide(this)) {
                this.pos.x += offset;
                offset = -(offset + (offset > 0 ? 1 : -1));
                // @ts-ignore
                if (offset > this.matrix[0].length) {
                    this.rotateMatrix(this.matrix, -direction);
                    this.pos.x = pos;
                    return;
                }
            }
        }
    };
    // principle: [a, b] = [b, a] => swaps position of a and b
    // rotate: transformation + reverse
    Player.prototype.rotateMatrix = function (matrix, direction) {
        var _a;
        // transformation
        for (var y = 0; y < matrix.length; ++y) {
            for (var x = 0; x < y; ++x) {
                _a = [
                    matrix[y][x],
                    matrix[x][y],
                ], matrix[x][y] = _a[0], matrix[y][x] = _a[1];
            }
        }
        // reverse
        if (direction > 0) {
            matrix.forEach(function (row) { return row.reverse(); });
        }
        else {
            matrix.reverse();
        }
    };
    Player.prototype.update = function (deltaTime) {
        if (!this.tetris.pause) {
            this.dropCounter += deltaTime;
            if (this.dropCounter > this.dropInterval) {
                this.drop();
            }
        }
    };
    return Player;
}());
export default Player;
