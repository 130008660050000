var Preview = /** @class */ (function () {
    function Preview(canvas) {
        this.canvas = canvas;
        this.context = canvas.getContext('2d');
        this.context.scale(25, 25);
        this.colors = [
            null,
            '#FF0D72',
            '#0DC2FF',
            '#0DFF72',
            '#F538FF',
            '#FF8E0D',
            '#FFE138',
            '#3877FF',
        ];
    }
    Preview.prototype.drawMatrix = function (matrix) {
        var _this = this;
        this.clear();
        matrix.forEach(function (row, y) {
            row.forEach(function (value, x) {
                if (value !== 0) {
                    _this.context.fillStyle = _this.colors[value];
                    _this.context.fillRect(x, y, 1, 1);
                }
            });
        });
    };
    Preview.prototype.clear = function () {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    };
    return Preview;
}());
export default Preview;
