var Tetris = require('./tetris').default;
var canvas = document.getElementById('tetris');
var tetris = new Tetris(canvas);
document.getElementById("start")
    .addEventListener("click", tetris.startGame.bind(tetris), false);
document.getElementById("restart")
    .addEventListener("click", tetris.restartGame.bind(tetris), false);
document.addEventListener('keydown', function (event) {
    var player = tetris.player;
    if (event.keyCode === 37) { // arrow left
        player.move(-1);
    }
    else if (event.keyCode === 39) { // arrow right
        player.move(1);
    }
    else if (event.keyCode === 40) { // arrow down
        player.drop();
    }
    else if (event.keyCode === 38 || // arrow up
        event.keyCode === 87 // W
    ) {
        player.rotate(1);
    }
    else if (event.keyCode === 81) { // Q
        player.rotate(-1);
    }
    else if (event.keyCode === 80) { // P
        tetris.togglePauseMode();
    }
    else if (event.keyCode === 32) { // space
        player.moveToBottom();
    }
});
