import Arena from "./arena";
import Player from "./player";
var Tetris = /** @class */ (function () {
    function Tetris(canvas) {
        this.canvas = canvas;
        this.context = canvas.getContext('2d');
        this.context.scale(20, 20);
        this.updateScore(0);
        this.pause = false;
        this.colors = [
            null,
            '#FF0D72',
            '#0DC2FF',
            '#0DFF72',
            '#F538FF',
            '#FF8E0D',
            '#FFE138',
            '#3877FF',
        ];
        this.showOverlay(true, null, true);
    }
    Tetris.prototype.startGame = function () {
        var _this = this;
        console.log('start game');
        this.arena = new Arena(12, 20);
        this.player = new Player(this);
        cancelAnimationFrame(this.repeater);
        var lastTime = 0;
        var update = function (time) {
            if (time === void 0) { time = 0; }
            var deltaTime = time - lastTime;
            lastTime = time;
            _this.player.update(deltaTime);
            _this.draw();
            _this.repeater = requestAnimationFrame(update);
        };
        update();
        this.gameNotStartet = true;
        this.gameOver = false;
        this.pause = false;
        this.showOverlay(false);
    };
    Tetris.prototype.restartGame = function () {
        console.log('restart game');
        this.startGame();
    };
    Tetris.prototype.createPiece = function (type) {
        if (type === 'T') {
            return [
                [0, 0, 0],
                [1, 1, 1],
                [0, 1, 0],
            ];
        }
        else if (type === 'O') {
            return [
                [2, 2],
                [2, 2],
            ];
        }
        else if (type === 'L') {
            return [
                [0, 3, 0],
                [0, 3, 0],
                [0, 3, 3],
            ];
        }
        else if (type === 'J') {
            return [
                [0, 4, 0],
                [0, 4, 0],
                [4, 4, 0],
            ];
        }
        else if (type === 'I') {
            return [
                [0, 5, 0, 0],
                [0, 5, 0, 0],
                [0, 5, 0, 0],
                [0, 5, 0, 0],
            ];
        }
        else if (type === 'S') {
            return [
                [0, 6, 6],
                [6, 6, 0],
                [0, 0, 0],
            ];
        }
        else if (type === 'Z') {
            return [
                [7, 7, 0],
                [0, 7, 7],
                [0, 0, 0],
            ];
        }
    };
    Tetris.prototype.draw = function () {
        this.context.fillStyle = '#000';
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
        // arena
        this.drawMatrix(this.arena.matrix, { x: 0, y: 0 });
        // player shadow
        this.drawMatrix(this.player.matrix, {
            x: this.player.pos.x,
            y: this.arena.landingPosition(this.player)
        }, '#110E11');
        // player
        this.drawMatrix(this.player.matrix, this.player.pos);
    };
    Tetris.prototype.drawMatrix = function (matrix, offset, color) {
        var _this = this;
        if (color === void 0) { color = null; }
        matrix.forEach(function (row, y) {
            row.forEach(function (value, x) {
                if (value !== 0) {
                    _this.context.fillStyle = color ? color : _this.colors[value];
                    _this.context.fillRect(x + offset.x, y + offset.y, 1, 1);
                }
            });
        });
    };
    Tetris.prototype.showOverlay = function (show, text, startBtn, restartBtn) {
        if (text === void 0) { text = null; }
        if (startBtn === void 0) { startBtn = false; }
        if (restartBtn === void 0) { restartBtn = false; }
        if (show) {
            this.canvas.style.opacity = '0.5';
            this.updateOverlayText(text);
        }
        else {
            this.canvas.style.opacity = '1';
            this.updateOverlayText(null);
        }
        document.getElementById('start').style.display = startBtn ? 'block' : 'none';
        document.getElementById('restart').style.display = restartBtn ? 'block' : 'none';
        document.getElementById('overlay').style.display = show ? 'block' : 'none';
    };
    Tetris.prototype.togglePauseMode = function () {
        if (this.gameNotStartet && !this.gameOver) {
            this.pause = !this.pause;
            this.showOverlay(this.pause, 'Pause');
            if (this.pause) {
                this.playSound('complete.ogg');
            }
        }
    };
    Tetris.prototype.playSound = function (file) {
        var audio = new Audio('assets/audio/' + file);
        audio.play();
    };
    Tetris.prototype.updateScore = function (score) {
        document.getElementById('score').innerText = '' + score;
    };
    Tetris.prototype.updateOverlayText = function (text) {
        document.getElementById('overlayText').innerText = text;
    };
    return Tetris;
}());
export default Tetris;
